.channel-list-item {
    display: flex;
    width: 100%;
    border-radius: 10px;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.channel-list-item:hover {
    color: red;
}

.channel-list-item h4 {
    color: red
}

.channel-active {
    background-color: rgb(45 100 158);
    color: white;
}

.channel-active h2 {
    color: white;
}

.channel-list-names-icon {
    color: #afa0b0;
    font-size: 16px;
    font-weight: normal;
    margin: 5px;
}

.channel-list-names {
    color: #afa0b0;
    font-size: 16px;
    font-weight: normal;
    margin: 6px 5px 6px 10px;
}

.channel-list-names:hover {
    color: white
}

.dm-image-container {
    width: 25px;
    height: 25px;
    overflow: hidden;
    border-radius: 10%;
    display: flex;
    align-content: center;
    justify-content: center;
    margin: 10px;
    background-color: rgb(71 71 71);
}

.group-dm-profile-picture{
    color: rgb(189 189 191);
    margin: 0;
    padding: 0;
    border: 0;
}