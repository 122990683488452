.workspace-form-container {
    display: flex;
    /* flex-direction: column; */
    /* align-items: center;
    justify-content: flex-start; */
    /* margin-right: 6em; */
    /* height: 100vh; */
    /* width: 110%; */
    position: relative;
    /* background-color: #4A164B; */
    font-family: 'Noto Sans', sans-serif;
    justify-content: center;
}

#name-title-text {
    /* color: white; */
}

#name-title-subtext {
    /* color: white; */
}

.create-workapce-page-left-area {
    background-color: #4A164B;
    width: 20%;

    left: 0;
}

.create-workspace-page-wrapper {
    display: flex;
    position: relative;
    /* width: 70%; */
    /* margin-left: 5rem; */
    /* margin-top: 1rem; */
    justify-content: center;
}

.create-workspace-page-left-area {
    background-color: #4A164B;
     width: 30%;
}

.channel-name-label,
.title-text {
    color: black
}

.error {
    color: #7e57c2;
}

.text-input-workspace {
    width: 50vw;
    /* max-width: 50vw; */
    height: 3.5em;
    margin-bottom: 1em;
    border: 1px solid #BBBABB;
    border-radius: 6px;
    padding-left: 1rem;
    /* box-shadow: 0 0 20px #1f3645; */
    font-family: 'Noto Sans', sans-serif;
    color: black;
    
}

#icon-title-text {
    /* color: white; */
}

#icon-title-subtext {
    /* color: white; */
}

.create-workspace-button {
    background-color: white;
    border-radius: 1em;
    border: none;
    color: white;
    padding: 2em;
    font-family: 'Noto Sans', sans-serif;
    font-weight: bolder;
    align-self: center;
    background-color: #4A164B;
    margin-top: 3rem;
    font-weight: 600;
}


.create-workspace-button:hover {
    background-color: #4a164bec
}

.create-workspace-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2em;
}

.form-field {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 4rem;
    /* justify-content: center; */
    align-items: flex-start;
}

.create-workspace-page-1 {
    display: flex;
        flex-direction: column;
        justify-content: flex-start;
}
