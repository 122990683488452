.message-dashboard-section {
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-color: white;
}

.messages-display {
    overflow: auto;
    /* margin-top: 2rem; */
    padding-top: 3.8rem;
    /* padding-top: 5.5rem; */
}

.manage-channels-show-index {
    height: 100%;
    overflow: auto;
}

.message {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.message-sender-first-name {
    font-family: 'Noto Sans', sans-serif;
    font-weight: 900;
    font-weight: bold;
    color: black;
}

/* .message-buttons > button {
    background-color: rgba(255, 255, 255, 0);
} */

.message:hover {
    background-color: rgba(231, 231, 231, 0.319);
}

.message.active {
    background-color: rgba(231, 231, 231, 0.319);
}

.message-content {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.image-container {
    min-width: 40px;
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 10%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 10px;
}

.message-profile-pic-horizontal {
    width: auto;
    height: 100%;
    object-fit: cover;
    background-color: white;
}

.message-profile-pic-vertical {
    width: 100%;
    height: auto;
    object-fit: cover;
    background-color: white;
}

.message-details {
    display: flex;
    flex-direction: column;
}

.message-details>h4 {
    color: black;
    margin: 0;
}

.message-details>p {
    color: black;
    margin: 0;
    font-family: 'Noto Sans', sans-serif;
    font-weight: 300;
}

.message-buttons>button {
    background-color: rgba(27, 29, 33, 0);
    color: #3F0F40;
    margin: 10px;
    border: none;
    border-radius: 6px;
    font-family: 'Noto Sans', sans-serif;
}

.message-dropdown {
    position: absolute;
    color: black;
    z-index: 100;
    background-color: #F8F8F8;
    box-shadow: 1px 1px 8px rgba(0, 0, 0, .25);
    /* padding: 1rem; */
    border-radius: 8px;
    right: 30px;
    bottom: 8px;
    width: 200px;
    font-family: 'Noto Sans', sans-serif;
    z-index: 999;
}

.message-edit-menu-items {
    padding: 1rem;
}

#message-update-wrap-1:hover {
    background-color:#DEDEDE;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    cursor: pointer;

}

#message-update-wrap-2:hover {
    background-color: #DEDEDE;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    cursor: pointer;

}

#form-1 {
    width: 100%;
}

#message-update-wrap-2 {
    color: red;
}

.message  button {
    display: none;
}

.message:hover button {
    display: flex;
}

.message-buttons {
    display: flex;
}


.edit-message-modal-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 2rem;
}
