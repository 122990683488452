.page {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    background-color: white;
    color: white;
}

.left-bar {
    margin-top: 10vh;
    background-color: #3F0F40;
    /* height: 100vh; */
    /* padding-right: 4rem; */
    /* border-right: solid 1px #515151; */
    /* border-top: solid 1px #515151; */
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Noto Sans', sans-serif;
    color: white;
    align-items: flex-start;
    width: 300px;
    z-index: 1;
}

.right-bar {
    margin-top: 10vh;
    height: 90vh;
    width: 85vw;
    position:relative
}

.dashboard-workspace-name {
    margin-top: .5em;
    margin-left: .5em;
    padding: 0;
    border: none;
    background: none;
    color: white;
    font-size: 18px;
    font-weight: bold;
    margin-left: 1.7rem;
    font-family: 'Noto Sans', sans-serif;
    /* border-bottom: solid 1px #515151; */
}

.fa-circle-chevron-down {
    font-size: 12px;
}

#workspace-arrow-down {
    margin-left: 0.6em;
    font-size: 0.6em;
    transform: scale(0.8);
}

.current-channel-name-bar {
    border-bottom: 1px solid rgb(221, 218, 218);
    top:0;
    position: absolute;
    /* top: 3.7rem; */
    width: 100%;
    background-color: white;
    color: black;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 99;
}

.active-channel-header-text {
    margin-left: 2rem;
    font-family: 'Noto Sans', sans-serif;
    font-weight: 600;
    font-size: 17px;
}

.add-to-channel-user-icon {
    margin-right: 2rem;
    color: #3F0F40;
    font-size: 18px;
    cursor: pointer;
}

.current-channel-name-text {
    margin-left: 2rem;
}


.messages-display::-webkit-scrollbar{
    background-color: #ffffff;
  }

  .messages-display::-webkit-scrollbar-thumb {
    background-color: #cacaca;
    border-radius: 10px;
  }
