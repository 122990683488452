.dashboard-navbar-container {
  background-color: #340D36;
  width: 100%;
  height: 10vh;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.home-icon {
  color: white;
  margin: 10px;
}

.dropdown-top-upper {
  display: flex;
  align-items: center;
}

.manage-profile-dropdown {
  font-size: 12px;
  color: #6d6d6d;
  cursor: pointer;
}


.user-menu-name-and-pic {
  display: flex;
  align-items: center;
  font-family: 'Noto Sans', sans-serif;
  font-weight: 600;
  margin-bottom: 1rem;
  margin-left: 1rem;
  justify-content: space-between;
  width: 90%;
}

#special-nav-image-open {
  cursor: pointer;
}

.nav-profile-section {
  display: flex;
  align-items: center;
  padding-right: 1rem;
}

.dropdown-sign-out-button {
  padding: 1rem;
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  cursor: pointer;
}

.profile-dropdown {
  background-color: #F7F7F7;
  position: absolute;
  z-index: 999;
  right: 10px;
  border-radius: 8px;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* padding: 0 1rem; */
  box-shadow: 1px 1px 8px rgba(0, 0, 0, .1);
  border: 1px solid #d1d1d1;
}

.signed-in-user-dropdown-wrapper {
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  width: 100%;
}


#nav-menu-img {
  width: 40px;
  height: 40px;
  border-radius: 5px;
}

#nav-menu-section-image {
  margin-left: 0;
}

.manage-user-nav-logo {
  margin-top: 1rem;
}

.manage-user-nav-logo:hover {
  cursor: pointer;
}

#signed-in-nav-logo {
  padding: 0 2rem;
  width: 10rem;
}

.user-icon-button {
  color: white;
  height: 20px;
  width: 20px;
  margin: 10px;
}

.nav-image-container {
  width: 35px;
  height: 35px;
  overflow: hidden;
  border-radius: 10%;
  display: flex;
  align-content: center;
  justify-content: center;
  margin: 10px;
}

.nav-profile-pic {
  width: auto;
  height: 100%;
  object-fit: cover;
  background-color: white;
}

/* .profile-dropdown {
  position: relative;
} */

.hidden {
  display: none;
}

.channels-button-modal {
  padding: 0;
  border: none;
  background: none;
}

.channel-edit-icon {
  color: white;
  margin-left: 5px;
}

.signed-out-nav-bar {
  background-color: #F6EFE8;
  padding: 1rem;
  padding-bottom: 4rem;
  padding-left: 6vw;
  padding-right: 6vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.banter-nav-logo {
  width: 20vw;
}

.signed-out-nav-bar-buttons {
  display: flex;
}

.signed-out-nav-bar-buttons>button {
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #4A164B;
  color: #4A164B;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 1rem;
  margin-right: 1rem;
  border: none;
}


.signed-out-nav-bar-buttons>button:hover {
  color: #EAB12B;
}

.signed-out-nav-bar.workspaces {
  background-color: #4A164B;
}

.signed-out-nav-bar.workspaces button {
  color: white;
}

.signed-out-nav-bar.workspaces button:hover {
  color: #EAB12B;
}


.dropdown-email-div {
  font-family: 'Noto Sans', sans-serif;
  padding-bottom: 2rem;
  /* border-bottom: 1px solid #c9c9c9;
  width: 100%; */
  font-size: 14px;
  margin-left: 1rem;
}


.dropdown-bio-wrapper {
  border-top: 1px solid #d1d1d1;
  border-bottom: 1px solid #d1d1d1;
  padding: 1rem;
}

.dropdown-user-bio {
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
}