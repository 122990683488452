.channel-dropdown-container {
  color: rgb(0, 0, 0);
  background-color: #3F0F40;
  /* border-bottom: solid 1px rgba(255, 255, 255, 0.391); */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  float: left;
  margin-left: .5rem;
  overflow: hidden;
  text-overflow: clip;
  width: 90%;
}

.channel-dropdown-container.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: 300ms ease;
  margin-top: .5rem;
}

.new-dropdown {
  position: absolute;
  left: 40px;
  top: 170px;
  background-color: #F8F8F8;
  z-index: 999;
  color: black;
  border-radius: 8px;
  width: 300px;
  /* padding: 1rem; */
  /* display: flex;
  flex-direction: column; */
  /* gap: 20px; */
  box-shadow: 1px 1px 8px rgba(0, 0, 0, .25);
  font-family: 'Noto Sans', sans-serif;
}

.manage-channels-menu-click {
  padding: 1rem;
  font-size: 14px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  cursor: pointer;
}

.manage-channels-menu-click:hover {
  background-color: #dedede;
}

.create-channel-div-label:hover {
  background-color: #dedede;
}

.channel-dropdown-container.inactive {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  transition: 300ms ease;
}

.channel-heading-2,
.channel-heading {
  border-radius: 3px 3px;
}

.channel-heading>h2 {
  margin: 0;
}

/* .caret-right,
.caret-down {
  border-radius: 2px 2px;
  width: 15px;
  color: #9a9a9d;
}

.caret-down:hover,
.caret-down:hover {
  background-color: rgb(205, 202, 202);
} */

.channel-heading:hover {
  color: white;
}

.channels-button-modal-sidebar.manage {
  padding: 1rem 0;
}

.channel-dropdown-heading-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: .5rem;
  margin-top: 1em;
  font-family: 'Noto Sans', sans-serif;
}

.add-icon {
  color: #9a9a9d;
  border-radius: 5px;
  margin-top: .1em;
  opacity: 0.8;
  padding: 5px;
  margin-left: auto;
  margin-right: 10px;
}

.add-icon:hover {
  background-color: rgb(205, 202, 202);
  cursor: pointer;
}

.channels-button-modal-sidebar {
  font-size: 16px;
  font-weight: normal;
  margin: 6px 5px 6px 5px;
  border-radius: 8px;
  border: none;
  background: none;
  color: rgb(173, 155, 155);
  font-family: 'Noto Sans', sans-serif;
}

.channels-button-modal-sidebar:hover {
  color: white;
}

.add-channel-button {
  padding: 0;
  border: none;
  background: none;
}

#fa-dropdown-arrow {
  size: large;
  border-radius: 5px;
  margin-top: .1em;
  opacity: 0.8;
  color: #9a9a9d;
  margin: 0 5px;
}

#fa-dropdown-arrow:hover {
  background-color: rgb(205, 202, 202);
  cursor: pointer;
}

#add-channel-sidebar:hover {
  color: #EBB22E;
}