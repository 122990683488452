.login-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 25em;
    background-color: white;
    border-radius: 12px;
    padding: 3rem;
}

.sign-in-inputs {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.sign-in-inputs input {
    height: 2rem;
    width: 100%;
    padding-left: 15px;
    /* display: flex;
    justify-content: center; */
    /* display: flex; */
    /* align-items: center;
    justify-content: center; */
}

.new-signup-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 12px;
    /* justify-content: center; */
    /* margin-right: 6em; */
    /* height: 48em; */
    /* width: 110%; */
    background-color: white;

    /* padding: 0 5rem; */
    padding-bottom: 2rem;
    width: 100%;
    padding: 3.5vw 5vw;
}

.new-signup-form-container input {
    width: 100%;
    padding-left: 10px;
    height: 2rem;
}

#login {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-input {
    display: flex;
    justify-content: center;
    width: 300px;
    /* height: 30px; */
    /* padding: 0.5em 0; */
}

.login-button {
    background-color: #4a154b;
    border-radius: 5px;
    border: none;
    color: #ffffff;
    padding: 1em;
    margin-top: 1em;
    margin-bottom: 1em;
    width: 100%;
    font-family: 'Noto Sans', sans-serif;
    font-size: 14px;
    font-weight: 600;
}

.login-button:hover{
    cursor: pointer;
    background-color: #4a154bec;
}

.login-labels {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #d1d2d3;
    margin-bottom: .5rem;
    width: 100%;
}

.text-input-login {
    /* width: 10em;
    height: 2em;
    margin: 0.5em 0; */
    border: 1px solid #b9b9b9;
    border-radius: 5px;
    padding: 6px 0;
    /* box-shadow: 0 0 20px #1f3645; */
}

.login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1em;
    gap: 1em;
}

.text-input-about-me {
    width: 100%;
    height: 8em;
    margin: 0.5em 0;
    border: 1px solid #cbcbcb;
    border-radius: .5em;
    color: #000000;
    font-family: 'Noto Sans', sans-serif;
    padding-left: 1rem;
    padding-top: .5rem;
}

#login-title {
    margin-top: 1.2em;
    font-weight: 600;
}
