.workspace-list-area {
    background-color: white;
    color: #4A164B;
    border-radius: 12px;
    text-align: center;
    /* padding: 0 10px; */
    width: 80vw;
    max-width: 1050px;
    border: 4px solid #6F456F;
}

.workspaces-index-header {
    background-color: rgb(236, 221, 236);
    margin: 0;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    padding: 1rem 0;
}

.workspace-card:hover {
    color: #EBB22E;
    cursor: pointer;
}

.workspace-card {
    display: flex;
    align-items: center;
}

.workspace-card-image-container {
    height: 60px;
    width: 60px;
    overflow: hidden;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px;
}

.workspace-card-image-horizontal {
    width: auto;
    height: 100%;
    object-fit: cover;
}

.workspace-card-image-vertical {
    width: 100%;
    height: auto;
    object-fit: cover;
}