.channel-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    background-color: #4a154b;
    font-family: 'Noto Sans', sans-serif;

}

#channel-create-header-main {
    color: white;
}

#banter-quote {
    color: white;
}

.create-channel-form-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
}


#create-channel-submit-button {
    width: 100%;
    height: 3rem;
    background-color: #4a154b;
    color: white;
    font-family: 'Noto Sans', sans-serif;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
}

#create-channel-submit-button:hover {
    background-color: #4a154bea;
    cursor: pointer;
}

#create-channel-input-field {
    width: 50%;
    padding: 1rem;
    border-radius: 5px;
    border: 1px solid #b6b6b6;
    width: 10.7rem;
    color: black;
}


.create-channel-modal-wrapper-2 {
    display: flex;
    width: 30vw;
    /* height: 20vh; */
    height: 70%;
    flex-direction: column;
    align-items: center;
    /* justify-content: space-between; */

}

#channel-label-item-text {
display: flex;
flex-direction: column;
align-items: center;
}

.channel-create-container {
    margin-bottom: 12em;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.text-input-channel {
    width: 8em;
    height: 2em;
    margin-bottom: 1em;
    border: 2px solid #214357;
    border-radius: 2em;
    box-shadow: 0 0 20px #1f3645;
}

.create-channel-button {
    background-color: white;
    color: #4a154b;;
    border-radius: 1em;
    border: none;
    padding: 1em;
    /* margin-left: 4.5em; */
    font-family: 'Noto Sans', sans-serif;
}

.create-channel-button:hover {
    cursor: pointer;
}

#banter-quote {
    /* margin-left: 3em; */
}
