.status-container {
  display: flex;
  margin-right: 3em;
}

select::-ms-expand {
  display: none;
}

.status-button {
  /* display:none; */
  padding: 0;
  border: none;
  background: none;
  font-size: 8px;
}

.image-container-2 {
  position: relative;
}

.status-button {
  position: absolute;
  top: 12em;
  left: 12em;
}

.status {
  font-size: 12px;

}

.profile-picture-modal {
  width: 96px;
  height: 96px;
}

.user-info-modal-wrapper {
  width: 40vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 60%;
  justify-content: space-between;
}

.user-image-container-modal {
  display: flex;
  width: 18em;
  height:6em;
}


.user-info-modal-info-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* margin-top: 2rem; */
}

.user-modal-name-status-container {
  gap: 20px;
}

.user-profile-button {
  /* display: flex;
  padding: 0;
  border: none;
  background: none;
  width: 100%;
  justify-content: flex-start;
  border-bottom: solid 1px;
  border-top: solid 1px;
  padding: .6em;
  margin-top: 1.5em;
  color: #ababad;
  font-size: large; */
}

.user-profile-button:hover {
  background-color: rgb(121, 177, 233, 0.3);
}

.user-icon-modal-sign-out-button {
  display: flex;
  padding: 0;
  border: none;
  background: none;
  width: 100%;
  justify-content: flex-start;
  padding: 5px;
  margin-bottom: 5px;
}
.user-icon-modal-sign-out-button:hover {
  background-color: rgb(121, 177, 233, 0.3);
}

#profile-names {
  margin-top: .5em;
  margin-left: 1em;
  font-size: x-large;
}
