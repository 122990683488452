
.yes-delete-yeet,
.no-delete,
/* .edit-channel-button, */
.delete-channel-button {
    background-color: #4a154b;
    border-radius: 1em;
    border: none;
    color: #ffffff;
    padding: 1em;
    margin-left: 1em;
    width: 8em;
} 

#edit-channel-submit-button {
    /* width: 80%; */
    /* justify-self: center; */
    background-color: #4a154b;
    color: white;
    border: none;
    width: 80%;
    font-family: 'Noto Sans', sans-serif;
    height: 2rem;
    font-weight: 600;
    border-radius: 5px;
}


#edit-channel-submit-button:hover {
    cursor: pointer;
    background-color: #4a154bec;
}


.edit-submit-form {
    display: flex;
    align-items: center;
    justify-content: center;
}

.channel-modal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 40vw;
}

#channel-modal-form {
    margin-bottom: 0;
}

#modal-buttons {
    margin-right: 4.5em;
    margin-bottom: 1em;
    width: 8em;
}

.add-channel-container {
    margin-bottom: 1em;
    /* margin-left: .5em; */
    margin-top: .5em
}

.channel-modal-border {
    border-bottom: solid 1px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* #new-messsage-sidebar {
    font-size: 16px;
    font-weight: normal;
} */
