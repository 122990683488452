.profile-page-container {
  color: white;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color:white;
  font-family: 'Noto Sans', sans-serif;
  align-items: center;
}

.profile-picture-container {
  margin-left: 5em;
}

.profile-edit-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items:center;
  gap: 30px;
}

.profile-edit-container input {
  width: 17rem;
  padding: 1rem;
}

.signup-edit-inner-form {
  width: 17rem;
    padding: 1rem;
}


#edit-profile-modal-wrapper {
  width: 40vw;
  padding: 2rem;
  height: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.edit-section-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-basis: 50%;
  border-bottom: solid 1px;
  margin-bottom: .5em;
  padding: 2em;
}


.profile-page-picture {
  height: 100px;
  width: 100px;
}

/* EDIT BUTTONS YO  */
/* --------------------------------------------------- */
.edit-profile-picture {
  color: #4A174B
}

.edit-profile-buttons {
  color: #4A174B;
  margin-left: 2em;
}

.edit-profile-picture:hover,
.edit-profile-buttons:hover {
  color: rgb(101, 103, 211, 0.6);
  cursor: pointer;
}

.edit-profile-button-containers {
  margin-left: 5em;
}

.delete-user-account-button{
  background-color: #e01d59;
  border-radius: 1em;
  border: none;
  color: #ffffff;
  padding: 1em;
}

.no-delete-button {
  background-color: #e01d59;
  border-radius: 1em;
  border: none;
  color: #ffffff;
  padding: 1em;
  margin-top: 2em;
}

.profile-picture-label {
  /* margin-left: 4em; */
}

.profile-edit-submit-button {
  background-color: #4A174B;
  border-radius: 5px;
  /* width: 17rem; */
  border: none;
  color: #ffffff;
  padding: .5rem;
  font-family: 'Noto Sans', sans-serif;
  font-weight: 600;
}

#edit-modal-title-text-heading {
  margin-bottom: 5rem;
}

.profile-edit-submit-button:hover {
  background-color: #4a174be2;
  cursor: pointer;
  
}

#password {
  margin-left: 4em;
}

#your-profile {
  margin-left: 1em;
}


.delete-user-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
