.create-message-form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: auto;
    height: 10vh;
    background-color: rgb(255, 255, 255);
    border-radius: 15px;
    border: 1px solid rgb(183, 193, 183);
    margin: 10px;
    bottom: 0;
}

.create-message-input {
    width: 450px;
    color: black;
    font-family: 'Noto Sans', sans-serif;
    font-weight: 300;
    background-color: rgb(255, 255, 255);
    margin: 10px;
    padding: 0;
    border: 0;
}

.create-message-input:focus {
    outline-width: 0;
}

.create-message-button {
    align-self: flex-end;
    background-color: rgb(20 120 92);
    border-radius: 10px;
    margin: 10px;
    padding: 10px;
    border: none;
}

.create-message-button:hover {
    background-color: rgb(60 130 100);
}

.create-message-icon {
    color: white;
}

.edit-message-textarea {
    width: 100%;
    height: 5rem;
    color: rgb(175 176 177);
    border: 1px solid #B9B9B9;
    padding: 1em;
    border-radius: 5px;
    color: black;
    font-family: 'Noto Sans', sans-serif;
}
