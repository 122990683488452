:root {
    --banter-blue: #36c5f0;
    --banter-red: #df1e5a;
    --banter-yellow: #df1e5a;
    --banter-green: #2fb67d;
    --banter-dark-blue: #165366;
    --banter-dark-red: #660d29;
    --banter-dark-yellow: #664d13;
    --banter-dark-green: #1b6647;
}

html {
    background-color: white
}

body {
    margin: 0;
    font-family: sans-serif;
}

button {
    cursor: pointer;
}

input[type="text"] {
    color: #ababad;
}

input[type="text"]::placeholder {
    color: #ababad;
    font-family: 'Noto Sans', sans-serif;
}

input[type="password"] {
    /* background-color: #000000; */
    /* padding-left: 2em; */
    color: #ababad;
}

input[type="password"]::placeholder {
    /* background-color: #222529; */
    /* padding-left: 2em; */
    color: #ababad;
}

#modal-content {
    background-color: white;
    height: 50%;
    border-radius: 12px;
    box-shadow: 0 0 20px #1f3645;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    font-family: 'Noto Sans', sans-serif;
    /* padding: 0 1rem; */
    max-width: 480px;
    min-width: 300px
}