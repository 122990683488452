.main-homepage-div {
    display: flex;
    margin: 0;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    font-family: 'Noto Sans', sans-serif;
}

.home-page-main-section-logged-out {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #F6EFE8;
    overflow: auto;
}

.home-page-main-section-logged-out-left {
    text-align: left;
    margin-right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 2rem;
}

.home-page-main-section-logged-out-image {
    width: 100%;
    height: 50vh;
    min-height: 300px;
    margin-left: 2rem;
    border-radius: 12px;
    object-fit: contain;
}

.banter-logged-out-header {
    font-family: 'Noto Sans', sans-serif;
    font-weight: 800;
    font-size: 40px;
}

.home-page-button-div {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
}

.home-page-demo-login {
    background-color: #4A164B;
    color: white;
    border: none;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 8px;
    font-weight: 800;
    font-size: 1rem;
    margin-bottom: .5rem;
}

.home-page-demo-login:hover {
    color: #EAB12B;
    box-shadow: 0 4px 12px rgba(0, 0, 0, .10);
    cursor: pointer;
}

.sign-up-button {
    background-color: rgb(255, 255, 255);
    border: none;
    color: #4A164B;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 8px;
    font-weight: 800;
    font-size: 1rem;
}

.sign-up-button:hover {
    color: #EAB12B;
    box-shadow: 0 4px 12px rgba(0, 0, 0, .10);
    cursor: pointer;
}

.main-section-logged-in {
    background-color: #4A164B;
    height: 82vh;
    width: 100vw;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.welcome-back-title {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.waving-hand {
    height: 3rem;
    margin-right: 5px;
}

.meet-the-team-home {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
}

.team-member-card {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, .15);
    padding: 2rem 2rem;
    margin: 0 20px;
    width: 8rem;
    height: 14rem;
    transition: transform 0.3s ease;
    /* Add transition effect */
    overflow: hidden;
    white-space: nowrap;
}

.team-card-socials-wrapper {
    display: flex;
    gap: 15px;
}

.team-card-socials-wrapper>a>i {
    font-size: 32px;
}

.team-card-socials-wrapper>a>i:hover {
    color: #EAB12B;
}

.meet-the-team-home a {
    text-decoration: none;
    color: #4A164B;
}

.team-member-card:hover {
    transform: scale(1.1);
    /* Apply transform on hover */
}

.team-member-card-image {
    height: 16vh;
    width: 9vw;
    border-radius: 10px;
    object-fit: cover;
}

.down-arrows {
    padding-top: 200px;
    font-size: 48px;
    color: #4A164B;
}

.team-flex-test {
    padding: 50px;
    margin-left: 200px;
    margin-right: 200px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
}

.home-page-logged-out-left {
    margin-right: 2rem;
}

.home-page-logged-out-left>h1 {
    margin-bottom: 5px;
}

.home-page-logged-out-left>p {
    margin: 0;
}

.home-page-logged-out-left button {
    font-family: 'Noto Sans', sans-serif;
}

.carousel-container {
    overflow: hidden;
    margin-bottom: 6rem;
}

.carousel-slides {
    display: flex;
    align-items: center;
    animation: carousel-slide 35s linear infinite both;
    animation-fill-mode: both;
}

.carousel-slide {
    margin-right: 6rem;
}

.carousel-image {
    width: 10vw;
}

.carousel-image>div:last-child {
    margin-right: 0;
}

.technologies-utilized-text {
    text-align: center;
    margin-bottom: 3rem;
}

.carousel-slide:last-child {
    margin-right: -100%;
    /* or margin-bottom: -100%, depending on the direction */
}


@keyframes carousel-slide {
    0% {
        transform: translateX(-150%);
    }

    100% {
        transform: translateX(100%);
    }
}


.add-a-workspace {
    display: flex;
    margin-top: 5rem;
    background-color: white;
    align-items: center;
    position: relative;
    height: 10vh;
    width: 80vw;
    justify-content: space-between;
    /* border: 4px solid rgba(255, 255, 255, .2); */
    border-radius: 9px;
    max-width: 1050px;
    border: 5px solid #6F456F;
}

.signed-in-workspaces-clipart {
    height: 135%;
    position: absolute;
    left: 0;
    top: 0;
    width: 20%;
    max-width: 210px;
}


.create-new-ws-container-button {
    margin-right: 2rem;
    background-color: #4a164b00;
    border: 1px solid #4A164B;
    color: #4A164B;
    border-radius: 8px;
    padding: .6rem;
    font-weight: 700;
    font-family: 'Noto Sans', sans-serif;
}

.create-new-ws-container-button:hover {
    color: white;
    background-color: #4A164B;
}

.test {
    display: flex;
    font-size: 40px;
}