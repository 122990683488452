.signup-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-right: 6em;
    height: 32em;
    width: 110%;
    background-color: #1b1d21;
}


#sign-in-button {
    background-color: #4A164B;
    font-size: 14px;
    font-family: 'Noto Sans', sans-serif;
    font-weight: 600;
    padding: .8rem;
}


#sign-in-button:hover {
    background-color: #4a164beb;
    color: white;
}