.workspace-modal-container {
  display: flex;
  flex-direction: column;
  width: 40vw;
  max-width: 480px;
  align-items: center;
}

.workspace-modal-button-container {
  width: 100%;
  margin-top: 2em;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.explore-channels-button-section.active {
  background-color: #2C649E;
  color: white;
  border-radius: 10px;
}

.workspace-icon-modal {
  width: 4em;
  height: 4em;
  border-radius: 12px;
}

.workspace-modal-name-desc-container {
  gap: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.workspace-upper-section {
  display: flex;
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 100%;
  border-top: 1px solid #512653;
  border-bottom: 1px solid #512653;
}


.workspace-modal-top {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#workspace-modal-button {
  width: 90%;
  color: white;
  background-color: var(--banter-green);
  font-family: 'Noto Sans', sans-serif;
  display: flex;
  justify-content: center;
  border: none;
  border-radius: 5px;
  padding: .6rem;
}

#workspace-modal-button:hover {
  background-color: var(--banter-dark-green);
}

.sign-out-workspace-button {
  width: 90%;
  color: #ffffff;
  background-color: var(--banter-red);
  font-family: 'Noto Sans', sans-serif;
  display: flex;
  justify-content: center;
  border: none;
  border-radius: 5px;
  padding: .6rem;
}

.sign-out-workspace-button:hover {
  background-color: var(--banter-dark-red);
}

#workspace-name {
  font-size: large;
}

.workspace-modal-title-container {
  margin-left: 2em;
}

.status-button-2 {
  margin-left: 1em;
  font-size: small;
  background-color: white;
  color: black;
  border: none;
}

.status-container {
  margin-right: 1em;
}

.search-input-login {
  height: 2em;
  margin: 0.5em 0;
  border: 1px solid #DDDDDD;
  border-radius: 10px;
  color: black;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  font-family: 'Noto Sans', sans-serif;
}

.explore-channels-button-section {
  margin-left: 1rem;
  margin-top: 1rem;
  font-size: 16px;
  color: rgb(173, 155, 155);
  width: 70%;
  display: flex;
  gap: 5px;
  align-items: center;
  padding: .5rem
}

.explore-channels-button-section:hover {
  color: white;
  cursor: pointer;
}

#search-icon {
  margin-left: 1em;
}

.search-result-container {
  display: flex;
  align-items: center;
  gap: 20px;
}

.name-and-pfp-workspace {
  display: flex;
}

.workspace-and-icon-menu {
  margin-right: .5rem;
  font-weight: 600;
}